<template>
  <vx-card title="Contenido">
    <p>Aquí se puede modificar la información más relevante de la página.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.title= trimString(content.title)" v-validate="'required'" name="title"
                    class="w-full" label="Titulo"
                    v-model="content.title"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-textarea @blur="content.description= trimString(content.description)" v-validate="'required'"
                       name="description" label="Descripción"
                       v-model="content.description"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/3">
          <vs-input @blur="content.facebook= trimString(content.facebook)" v-validate="'required'"
                    name="facebook" class="w-full" label="Facebook"
                    v-model="content.facebook"/>
          <span class="text-danger text-sm"
                v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input @blur="content.instagram= trimString(content.instagram)" v-validate="'required'"
                    name="instagram" class="w-full" label="Instagram"
                    v-model="content.instagram"/>
          <span class="text-danger text-sm"
                v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input @blur="content.tiktok= trimString(content.tiktok)" v-validate="'required'"
                    name="instagram" class="w-full" label="Tiktok"
                    v-model="content.tiktok"/>
          <span class="text-danger text-sm"
                v-show="errors.has('tiktok')">{{ errors.first('tiktok') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/3">
          <vs-input @blur="content.phone= trimString(content.phone)" v-validate="'required'"
                    name="phone" class="w-full" label="Telefono"
                    v-model="content.phone"/>
          <span class="text-danger text-sm"
                v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input @blur="content.whatsApp= trimString(content.whatsApp)" v-validate="'required'"
                    name="whatsApp" class="w-full" label="WhatsApp"
                    v-model="content.whatsApp"/>
          <span class="text-danger text-sm"
                v-show="errors.has('whatsApp')">{{ errors.first('whatsApp') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input @blur="content.email= trimString(content.email)" v-validate="'required'"
                    name="email" class="w-full" label="Email"
                    v-model="content.email"/>
          <span class="text-danger text-sm"
                v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <!--Pre banner-->
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <vs-input @blur="content.prebannerText= trimString(content.prebannerText)"
                    name="prebannerText" class="w-full" label="Texto de banner superior"
                    v-model="content.prebannerText"/>
        </div>
        <div class="vx-col w-1/2">
          <vs-input @blur="content.prebannerLink= trimString(content.prebannerLink)"
                    name="prebannerLink" class="w-full" label="Slug de banner superior"
                    v-model="content.prebannerLink"/>
        </div>
      </div>

      <!--Hour Limit-->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input v-validate="'required'" name="title"
                    class="w-full" label="Hora límite"
                    v-model.number="content.limit"/>
          <span class="text-danger text-sm"
                v-show="errors.has('limit')">{{ errors.first('limit') }}</span>
        </div>
      </div>
      <!--End--->

      <!--Disabled dates-->
      <div class="mt-5">
        <h6 class="mb-4">Deshabilitar días</h6>
        <vc-date-picker
            mode='multiple'
            v-model='content.dates'/>
      </div>
      <!--End-->

      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>

    </div>
  </vx-card>
</template>

<script>
import trimString from '../mixins/trimString'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  name: 'Content',
  mixins: [
    trimString
  ],
  data () {
    return {
      content: {},
      progress: false,
      initProgress: false
    }
  },
  /**
   * Get data on firestore
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.initProgress = true
      await db.collection('content').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content = {
            id: doc.id,
            ...doc.data()
          }
        })

        // eslint-disable-next-line no-unused-vars
        const newDates = []
        this.content.dates.forEach((d) => {
          newDates.push(d.toDate())
        })
        this.content.dates = newDates
        this.initProgress = false
      })
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  computed: {
    isFormValid () {
      return !this.errors.any()
    }
  },
  methods: {
    /**
     *  Save data in firestore
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          await contentRef.update({
            ...cloneContent,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })

          this.progress = false
          this.$vs.notify({
            color: 'success',
            title: 'Contenido',
            text: 'Contenido modificado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    }
  }
}
</script>
